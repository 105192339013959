import Swal from "sweetalert2/dist/sweetalert2.js";
import {dateConvert} from "@/core/filters/datatime";
import activepassivetypes from "@/core/data/activepassivetypes";
import reservationtypes from "@/core/data/reservationtypes";
import posTypes from "@/core/data/postypes";

export const libTools = {
    method: {
        swalError(prmConfirmButtonText, prmText){
            Swal.fire({
                icon: "error", buttonsStyling: false, customClass: { confirmButton: "btn fw-bold btn-light-danger", },
                text: prmText,
                confirmButtonText: prmConfirmButtonText,
            }).then(function () {
                //
            });
        },

        swalSuccess(prmConfirmButtonText, prmText){
            Swal.fire({
                icon: "success", buttonsStyling: false, customClass: { confirmButton: "btn fw-bold btn-light-primary", },
                text: prmText,
                confirmButtonText: prmConfirmButtonText,
            }).then(function () {
                //
            });
        },

        localeStA(prmValue, prmDigit) {
            prmValue = this.getValueOrZero(prmValue);
            return Number(prmValue).toLocaleString(undefined, { minimumFractionDigits: prmDigit,  maximumFractionDigits: prmDigit})
        },

        getSelectedDateFromFlatPicker(prmFlatPicker, prmIndex) {
            if ((prmFlatPicker as any).selectedDates.length>prmIndex) {
                return dateConvert.method.dateToflatpickerFormated((prmFlatPicker as any).selectedDates[prmIndex], "YYYY-MM-DD");
            }
            return "";
        },

        getTransferTypeText(prmTransferType) {
            if (prmTransferType==="PAX") return "Kişi Başı";
            if (prmTransferType==="CAR") return "Sefer Başı";
            return "";
        },

        getBankPosTypeText(prmBankPosType) {
            if (prmBankPosType==="VIRTUAL") return "Sanal";
            if (prmBankPosType==="REAL") return "Gerçek";
            return "";
        },

        getCurParity (prmCurRates, prmAmount, prmCur){
            const myCur = parseFloat(prmCurRates["Cur" + prmCur]);
            const myPrice= parseFloat(prmAmount) / myCur;
            return myPrice;
        },

        getCoreDataByValue(prmArr, prmValue) {
            //console.log(prmValue);
            for (let i=0; i<prmArr.length; i++){

                const each = prmArr[i];
                //console.log("each " + i + " " + each.Value + " " + each.Title );
                if(each.Value === prmValue) {
                    //console.log("bulduğum " + " " + each.Value);
                    return each;
                }

            }
            return {};
        },

        toggleClass(prmElId, prmClassName) {
            const elCont = document.getElementById(prmElId) as HTMLElement;
            if (elCont!==null) {
                if (elCont.classList.contains(prmClassName)) {
                    elCont.classList.remove(prmClassName);
                } else {
                    elCont.classList.add(prmClassName);
                }
            }
        },

        getReservationTypeText (prmReservationType) {
            for (let i=0; i<reservationtypes.TYPES.length; i++) {
                const eachReservationType = reservationtypes.TYPES[i];
                if (eachReservationType.Value === prmReservationType){
                    return eachReservationType.Title;
                }
            }
            return "";
        },

        getBankPosIsActiveText (prmIsActive) {
            for (let i=0; i<activepassivetypes.TYPES.length; i++) {
                const eachActivePassiveType = activepassivetypes.TYPES[i];
                if (eachActivePassiveType.Value === prmIsActive + ""){
                    return eachActivePassiveType.Title;
                }
            }
            return "";
        },

        getCurrentSign(prmCur) {
            if (prmCur === "TRY") return "₺";
            if (prmCur === "USD") return "$";
            return "₺";
        },

        getFlightClassText (prmClass) {
            if (prmClass === "ECO") return "Ekonomi";
            if (prmClass === "PROTOCOL") return "Protokol";
            return prmClass;
        },

        getDayOfWeek(prmDate) {
            let vvDayOfWeek = prmDate.getDay()	;
            if (vvDayOfWeek===0) vvDayOfWeek = 7;
            return vvDayOfWeek;
        },

        getPaxTypeName(prmPaxType) {
            if (prmPaxType==="ADT") return "Yetişkin";
            if (prmPaxType==="CHD") return "Çocuk";
            if (prmPaxType==="INF") return "Bebek";
            return "Yetişkin";
        },

        getPaxGenderName(prmPaxGender) {
            if (prmPaxGender==="F") return "Bayan";
            return "Bay";
        },

        getFlightDirectionName(prmFlightDirection) {
            if (prmFlightDirection==="Dep") return "Gidiş";
            if (prmFlightDirection==="Ret") return "Dönüş";
            return "";
        },

        getValueOrEmptyString(prmVal) {
            if (prmVal!==undefined && prmVal!==null) {
                return prmVal;
            }
            return "";
        },

        getValueOrZero(prmVal) {
            if (prmVal!==undefined && prmVal!==null) {
                return prmVal;
            }
            return 0;
        },

        getElValue(prmElId){
            let rv = "";
            const el = document.getElementById(prmElId) as any;
            if (el!==undefined && el!==null) {
                rv = el.value;
            }
            return rv;
        },
        setElValue(prmElId, prmValue){
            const el = document.getElementById(prmElId) as any;
            if (el!==undefined && el!==null) {
                el.value = prmValue;
            }
        },

        getElSelectedText(prmElId){
            let rv = "";
            const el = document.getElementById(prmElId) as any;
            if (el!==undefined && el!==null) {
                rv = el.options[el.selectedIndex].text;
            }
            return rv;
        },

        getPaxCount_By_Result(prmResult, prmPaxType) {

            if (prmResult!==undefined) {


                if (prmPaxType==="ADT" && prmResult.PaxADT!==undefined) {
                    return Number(prmResult.PaxADT);
                }

                if (prmPaxType==="CHD" && prmResult.PaxCHD!==undefined) {
                    return Number(prmResult.PaxCHD);
                }

                if (prmPaxType==="INF" && prmResult.PaxINF!==undefined) {
                    return Number(prmResult.PaxINF);
                }

            }
            return 0;
        },

        getParsedObj(prmJSONRemarks) {
            if (prmJSONRemarks!==undefined && prmJSONRemarks!==null && prmJSONRemarks!=="") {
                //console.log("getParsedObj: "+prmJSONRemarks);
                const objParsed = JSON.parse(prmJSONRemarks);
                return objParsed;
            }
            return {};
        },

        getRemarksJSONPaxes(prmJSONRemarks) {
            let arrPaxes = [];
            if (prmJSONRemarks!==undefined && prmJSONRemarks!==null) {
                const objTemp = JSON.parse(prmJSONRemarks);
                if (Array.isArray(objTemp)) {
                    arrPaxes = objTemp;
                } else {
                    arrPaxes.push(objTemp);
                }
            }
            return arrPaxes;
        },

        getRemarksJSONFlightTimes(prmJSONRemarks) {
            if (prmJSONRemarks!==undefined && prmJSONRemarks!==null && prmJSONRemarks!=="") {
                const objFlightTime = JSON.parse(prmJSONRemarks);
                return objFlightTime;
            }
            return {};
        },

        getFirstPax(prmPaxes) {
            console.log("getFirstPax "+JSON.stringify(prmPaxes));
            if (typeof prmPaxes === "string") {
                prmPaxes = this.getParsedObj(prmPaxes);
            }
            if (prmPaxes!==undefined && prmPaxes!==null && prmPaxes.length>0) {
                return prmPaxes[0];
            }
            return {};
        },

        getPaxes(prmReservationDetail, prmProductType) {
            const arrPaxes = [];
            if (prmReservationDetail.ITEMS!==undefined) {
                let vvFlightUqinue = "";
                for (let i=0; i<prmReservationDetail.ITEMS.length; i++) {
                    const each = prmReservationDetail.ITEMS[i];
                    if (each.ProductType=== prmProductType) {
                        if (vvFlightUqinue==="") {
                            vvFlightUqinue = each.From+"_"+each.To+"_"+each.RemarksVC4;
                        }
                        if (vvFlightUqinue === each.From+"_"+each.To+"_"+each.RemarksVC4) {
                            if (each.RemarksJSON1!==undefined &&each.RemarksJSON1!==null) {
                                const tmpPax = JSON.parse(each.RemarksJSON1);
                                arrPaxes.push(tmpPax);
                            }
                        }
                    }
                }
            }
            return arrPaxes;
        },

         getFirstPaxNameSurname(prmPaxes) {
            //console.log("getFirstPaxNameSurname "+prmType+" prmPaxes: "+JSON.stringify(prmPaxes));
            if (prmPaxes!==undefined && prmPaxes!==null) {
                //console.log("getFirstPaxNameSurname "+prmType+" prmPaxes.length: "+prmPaxes.length);
                if (prmPaxes.length>0) {
                    if (prmPaxes[0].PaxName!==undefined) {
                        return prmPaxes[0].PaxName+" "+prmPaxes[0].PaxSurname;
                    }
                    if (prmPaxes[0].Name!==undefined) {
                        return prmPaxes[0].Name+" "+prmPaxes[0].Surname;
                    }
                }
            }
            return "";
        },

        convertPaxesToGeneral(prmPaxes) {

            console.log("convertPaxesToGeneral START "+JSON.stringify(prmPaxes));

            const rv = [];
            for (let i=0; i<prmPaxes.length; i++) {
                const eachPax = prmPaxes[i];
                const vvPax = {
                    PaxType: eachPax.Type,
                    PaxName: eachPax.Name,
                    PaxSurname: eachPax.Surname,
                    PaxGender: eachPax.Gender,
                    PaxID: eachPax.TcNo,
                    PaxBirthdate: eachPax.BirthDate,
                };
                rv.push(vvPax);
            }
            return rv;
        },

        getDiscount(prmValOrj, prmValDisCounted) {
            return  100 -( Number(prmValDisCounted) / Number(prmValOrj) * 100.0);
        },

        convertTurkishToEnglish(prmVal) {
            if (prmVal === undefined || prmVal===null) return "";
            prmVal = prmVal.replaceAll("ı", "i");
            prmVal = prmVal.replaceAll("ı", "i");
            prmVal = prmVal.replaceAll("ı", "i");
            prmVal = prmVal.replaceAll("İ", "I");
            prmVal = prmVal.replaceAll("İ", "I");
            prmVal = prmVal.replaceAll("İ", "I");
            prmVal = prmVal.replaceAll("İ", "I");
            prmVal = prmVal.replaceAll("ğ", "g");
            prmVal = prmVal.replaceAll("ğ", "g");
            prmVal = prmVal.replaceAll("ğ", "g");
            prmVal = prmVal.replaceAll("Ğ", "G");
            prmVal = prmVal.replaceAll("Ğ", "G");
            prmVal = prmVal.replaceAll("Ğ", "G");
            prmVal = prmVal.replaceAll("ş", "s");
            prmVal = prmVal.replaceAll("ş", "s");
            prmVal = prmVal.replaceAll("ş", "s");
            prmVal = prmVal.replaceAll("Ş", "S");
            prmVal = prmVal.replaceAll("Ş", "S");
            prmVal = prmVal.replaceAll("Ş", "S");
            return prmVal;
        },

        getAilrlineIcon(prmAirlineCode) {
            if (prmAirlineCode=="PC") return "media/airlines/logos/PC_PEGASUS AIRLINES_35x35.gif"
            if (prmAirlineCode=="FH") return "media/airlines/logos/FH_LYDIA_AIRLINES_35x35.png"
            return "";
        }

    }
}

class Lib_Tools {

}

export default Lib_Tools;