const MasterConfig =
    {
        general:
            {
                imagePath: "https://crtbooking.com/api/assets/images/",//production
                uploadPath: "https://crtbooking.com/fup/put.php",//production
                //imagePath: "http://localhost:8085/assets/images/",//Development
                //uploadPath: "http://localhost/fup/put.php",//Development
                defaultImage: "media/avatars/blank.png",
                defaultDecimal: 2,
            },
        search:
            {
                maxADT: 6,
                maxCHD: 4,
            },
        upload:
            {
                path: "https://crtbooking.com/fup/put.php",//production
                //path: "http://localhost/fup/put.php",//Development
                format: ['.png', '.jpg', '.jpeg', '.pdf', '.xls', '.xlsx', '.docx', '.doc', '.pptx', '.ppt', '.pptm'],
                maxSize: 52428800,//50 MB
            },
        roomPrice:
            {
                maxAdultCount: 6,
                maxChildCount: 4,
                maxChildAgeGroupCount: 4,
                maxChildAge:18,
                adultData:[[], [], [], [], [], []],
                childData:[[[]], [[]], [[]], [[]]],
            },
        mice:
            {
                requestAddDay: 2,
                requestDateCount: 10,
            },
    };

export default MasterConfig;
