
import {computed, defineComponent, ref,} from "vue";
import store from "@/store";
import MasterConfig from "@/core/config/MasterConfig";
import {dateConvert} from "@/core/filters/datatime";
import supplierType from "@/core/data/supplierTypetypes";
import {libTools} from "@/core/appcore/libs/Lib_Tools";


export default defineComponent({
    name: "suppliers-lists",
    components: {
    },
    data: function () {
        return {
            imgUrl: '',
            searchName: '',
            sortField: '',
            sortType: '',
            filterField: ['Title'],
            filterFieldName: ['İsim'],
        }
    },

    props: {
        widgetClasses: String,
        tableData: Object,
    },

    computed: {

        filteredTableList: function () {
            if (this.tableData !== undefined){
                return this.tableData.filter(filteredList =>
                        (filteredList.Title !== undefined && !(filteredList.Title).toLowerCase().indexOf(this.searchName.toLowerCase()))
                    //!(systemuser.Lastname).toLowerCase().indexOf(this.searchName.toLowerCase()) ||
                    //!(systemuser.Username).toLowerCase().indexOf(this.searchName.toLowerCase()) ||
                    //!(systemuser.Email).toLowerCase().indexOf(this.searchName.toLowerCase()) ||
                    //!(systemuser.Mobile).toLowerCase().indexOf(this.searchName.toLowerCase())
                );
            }else{
                return [];
            }
        },

    },

    methods: {
        ...dateConvert.method,

        sortData(attr, data) {
            if (this.sortField == '') {
                this.sortType = "asc";
            } else {
                this.sortType = this.sortField == attr && this.sortType == 'asc' ? "desc" : "asc";
            }
            this.sortField = attr;

            if (this.sortType == 'asc' || this.sortType == 'desc') {
                this[data].sort((a, b) => {
                    let fa = '';
                    let fb = '';
                    if (typeof (attr) == 'string') {
                        if (this.sortType == 'asc') {
                            fa = a[attr].toLowerCase();
                            fb = b[attr].toLowerCase();
                        } else {
                            fb = a[attr].toLowerCase();
                            fa = b[attr].toLowerCase();
                        }
                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    } else {
                        return this.sortType == 'asc' ? (a.Mobile - b.Mobile) : (b.Mobile - a.Mobile);
                    }
                });
            }
        }
    },

    setup(props) {
        const myParams = computed(() => {
            return store.getters.generalParams;
        });
        return {
            libTools,
            supplierType,
            MasterConfig,
            myParams,
        }
    },

});
