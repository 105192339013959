
import {computed, defineComponent, onMounted, ref} from "vue";

import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
//import AlphabeticKeyboardHeader from "@/components/common/AlphabeticKeyboardHeader.vue";
import List from "@/components/flight/suppliers/List.vue";

export default defineComponent({
    name: "flight-suppliers-list",
    components: {
        // AlphabeticKeyboardHeader,
        List,
    },
    props:{
    },

    setup() {

        var myLoading= ref(false);

        /* function alphselected(prmValue) {
           console.log(prmValue)
           myLoading.value = true;
           store.dispatch(Actions.SUPPLIERS_LIST_ALL, {'Title': prmValue})
               .then(() => {
                 myLoading.value = false;
               });


         }*/

        onMounted(() => {
            setCurrentPageBreadcrumbs("Tedarikçiler Listesi", ["Tedarikçiler", "Liste"]);
        });
        store.dispatch(Actions.SUPPLIERS_LIST_ALL, {});
        const myList = computed(() => {
            return store.getters.suppliersList;
        });
        return {
            myList,
            //alphselected,
            myLoading,

        };
    },
});
